body {
    display: flex;
    flex-direction: column;
    font-family: "Noto Sans JP", sans-serif;
    width: 100%;
    height: 100svh;
    overflow: hidden;
    margin: 0;
    background: #161616;
}

#root{
    display: flex;
    flex-direction: column;
    height: 100svh;
}